@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* > * {
  font-family: "Poppins", cursive;
  margin: 0;
}

.logo {
  color: aliceblue;
  width: 10;
}

.App {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.register {
  max-width: 978px;
  width: 100vw;
  display: flex;

  background-color: white;
}

.register span {
  color: #adb5bd;
}

#form {
  max-width: 320px;
  width: 100vw;
  margin: 2em auto;
}

#form > input,
select,
.btn {
  border: 1px solid #142433;
  padding: 0.8em 1em;
}

#form > input:focus {
  outline: none;
}

#form > .btn {
  background-color: #6d56c2;
  color: #ffffff;
  font-size: 1em;
  cursor: pointer;
}

.flex {
  display: flex;
  gap: 1em;
}

.flex-col {
  flex-direction: column;
}

.register .col-1 {
  margin: auto;
  padding: 3em 0;
}

.register .col-2 Img {
  width: 480px;
  height: 100%;
  object-fit: cover;
  align-self: flex-end;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: none;
}

@media screen and (min-width: 860px) {
  .register .col-2 Img {
    display: initial;
  }
}
.sr-only {
  margin-left: -20px;
}
.mobile-number {
  margin-left: -40px;
  margin-right: 10px;
}

/* CSS variables. */
:root {
  --PhoneInput-color--focus: #03b2cb;
  --PhoneInputInternationalIconPhone-opacity: 0.8;
  --PhoneInputInternationalIconGlobe-opacity: 0.65;
  --PhoneInputCountrySelect-marginRight: 0.5em;
  --PhoneInputCountrySelectArrow-width: 0.3em;
  --PhoneInputCountrySelectArrow-marginLeft: var(
    --PhoneInputCountrySelect-marginRight
  );
  --PhoneInputCountrySelectArrow-borderWidth: 1px;
  --PhoneInputCountrySelectArrow-opacity: 0.45;
  --PhoneInputCountrySelectArrow-color: currentColor;
  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountrySelectArrow-transform: rotate(45deg);
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 1.4em;
  --PhoneInputCountryFlag-borderWidth: 1px;
  --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
}

.PhoneInput {
  /* This is done to stretch the contents of this component. */
  display: flex;
  align-items: center;
}

.PhoneInputInput {
  /* The phone number input stretches to fill all empty space */
  flex: 1;
  /* The phone number input should shrink
	   to make room for the extension input */
  min-width: 10;
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #464a4c;
  background-color: #fff;
  background-image: none;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.PhoneInputCountryIcon {
  width: calc(
    var(--PhoneInputCountryFlag-height) *
      var(--PhoneInputCountryFlag-aspectRatio)
  );
  height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
  width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
  /* Removed `background-color` because when an `<img/>` was still loading
	   it would show a dark gray rectangle. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom. */
  background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
  /* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom,
	   so an additional "inset" border is added. */
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
  /* Fixes weird vertical space above the flag icon. */
  /* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
  display: block;
  /* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
	   Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
  width: 100%;
  height: 100%;
}

.PhoneInputInternationalIconPhone {
  opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
  opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */

.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}

.PhoneInputCountrySelect[disabled],
.PhoneInputCountrySelect[readonly] {
  cursor: default;
}

.PhoneInputCountrySelectArrow {
  display: block;
  content: "";
  width: var(--PhoneInputCountrySelectArrow-width);
  height: var(--PhoneInputCountrySelectArrow-width);
  margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
  border-style: solid;
  border-color: var(--PhoneInputCountrySelectArrow-color);
  border-top-width: 0;
  border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  border-left-width: 0;
  border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  transform: var(--PhoneInputCountrySelectArrow-transform);
  opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelect:focus
  + .PhoneInputCountryIcon
  + .PhoneInputCountrySelectArrow {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor--focus),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus
  + .PhoneInputCountryIcon
  .PhoneInputInternationalIconGlobe {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}
.backbuttonofsignup {
  display: flex;
}

.form-style {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.remove-margin {
  margin: 0;
}

.checkbox-layout {
  display: "flex";
  flex-direction: row;
  flex-wrap: nowrap;
}

.grid-container {
  margin: 0 auto;
}

.signup-button {
  margin: 0;
  border: 0;
}

.remove-margin-bottom {
  margin: 0;
}
.checkbox-error-message {
  position: relative;
  bottom: 10px;
  left: 8px;
}
.password-input-container {
  position: relative;
}

.eye-icon {
  position: absolute;
  top: 50%;
  right: 35px; /* Adjust this value to position inside the field */
  transform: translateY(-50%);
  cursor: pointer;
  background: none;
  border: none;
  color: #666;
  z-index: 2;
  margin-right: 10%;
}

/* Ensure proper padding for the password input */
input[type="password"],
input[type="text"] {
  padding-right: 40px !important;
}
.signup-otp-button {
  margin-top: 6px;
  margin-right: 10px;
  background: linear-gradient(90deg, #65a3dd 0%, #1584d4 48.6%, #5d8fbd 100%);
  color: white;
  border: none;
  padding: 0.5rem 0.75rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 15px;
  &:hover {
    background-color: #004d80; // Darker shade for hover
    color: white;
  }
}

.signup-otp-input {
  margin-top: 4px;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  border: 2px solid #0073b1; // Border color
  border-radius: 15px;
  max-width: 90%;
  &:focus {
    border-color: #004d80; // Darker shade for focus
    outline: none;
  }
}

.signup-otp-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
}

.signup-otp-timer {
  margin-top: 6px;
  font-size: 16px;
  color: #000;
}

.signup-otp-action {
  background-color: transparent;
  border: none;
  color: #0073b1; // Change to your preferred color
  cursor: pointer;
  &:hover {
    color: #004d80; // Darker shade for hover
  }
}

.align-verify-icon {
  display: "flex";
  align-items: "center";
  gap: 5px;
}

.form-error {
  color: red;
}

.form-label-partnerType {
  z-index: 1000;
}

.form-control {
  max-width: 90%;
  border-radius: 15px !important;
}

.css-1s2u09g-control {
  /* This targets React-Select components */
  max-width: 90%;
  border-radius: 15px !important;
}

/* Container width adjustment */
.form-style {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 0px;
}

/* Adjust Grid container width */
.MuiGrid-container {
  max-width: 100%;
  margin: 0 auto !important;
}

/* Target React-Select container */
.css-13cymwt-control {
  border-radius: 15px !important;
  max-width: 90% !important;
}

/* Target React-Select dropdown menu */
.css-t3ipsp-control {
  border-radius: 15px !important;
  max-width: 90% !important;
}

/* Target React-Select menu list */
.css-1nmdiq5-menu {
  border-radius: 15px !important;
  max-width: 90% !important;
}

@media (max-width: 768px) {
  .form-style {
    padding: 0 10px;
  }
  
  .form-control {
    min-height: 40px;
  }
}

.custom-swiper-pagination {
  position: relative;
  bottom: -5px !important;
  text-align: center;
}
