.employee-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  gap: 2rem;
  padding: 1rem;
}

.employee-title {
  font-family: 'Trykker', serif;
  font-size: clamp(32px, 5vw, 58.12px);
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: min(480px, 90%);
  height: auto;
  background: linear-gradient(90deg, #553EBD 0%, #7A538E 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.search-box {
  width: min(90%, 600px);
  position: relative;
}

.search-field {
  width: 100%;
  padding: 12px;
  border-radius: 25px;
  border: 1px solid #ccc;
  padding-right: min(140px, 30%);
  font-size: clamp(14px, 2vw, 16px);
}

.search-button {
  position: absolute;
  right: 8px;
  top: 0%;
  border-radius: 20px;
  padding: 8px 16px;
  border: none;
  background: linear-gradient(90deg, #553EBD 0%, #7A538E 100%);
  color: white;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  z-index: 1;
  font-size: clamp(12px, 1.5vw, 14px);
}

@media screen and (max-width: 480px) {
  .employee-title{
    margin-left: 5%;
  }
  .search-button {
      padding: 8px 25px;
      right: -5%;
      gap: 0;
  }
  
  .employee-container {
      gap: 1.5rem;
  }
  .search-field{
    margin-left: 7%;
  }
}
@media screen and (min-width: 800px) and (max-width: 1200px) {
  .employee-container{
    margin-left: 10%;
    gap: 1.75rem;
  }
  .employee-title {
    font-size: 42px;
    margin: 0 auto;
    width: 85%;
  }

  .search-box {
    width: min(90%, 600px);
  }

  .search-button {
    padding: 8px 20px;
    right: 2%;
  }
}

.gradient-button {
  background: #ffffff;
  color: #553EBD;
  border-radius: 15px;
  padding: 8px 20px;
  font-size: 14px;
  border: 1px solid #553EBD;
  cursor: pointer;
  transition: all 0.3s ease;
}

.gradient-button:hover {
  background: linear-gradient(90deg, #553EBD 0%, #7A538E 100%);
  color: #ffffff;
}

@media screen and (min-width:760px) and (max-width: 1200px) {
     .employee-container{
      margin-left: 15%;
     }
  }

@media screen and (min-width:600px) and (max-width: 760px) {
  .employee-container{
    margin-left: 20%;
  }
}